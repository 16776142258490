/* eslint-disable prefer-rest-params */
/* eslint-disable import/no-webpack-loader-syntax */
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import mapboxgl, { MapLayerMouseEvent, Popup, VectorSource } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector, useMobile } from '../hooks';
import store from '../store';
import GeoHelper from '../utils/geo-helpers.utils';
import {
  addCustomMapControls,
  latLngDecimalToDMS,
  setMapDrawControlsState,
  themedDraw,
} from './map-controls.utils';

import { loadCachedMapImage, updateMapImageCache } from './map-image-cache';
import MapLayerManager from './map-layer-manager/map-layer-manager.utils';
import './map.scss';
import {
  LayerProperty,
  setDrawType,
  setMap,
  setMapInitialised,
  setMapZoom,
} from './map.slice';

import PopupBase from '../common-components/popup-base/popup-base';
import { MAPBOX_ACCESS_TOKEN } from '../environment';
import useAccessControl from '../hooks/access-control/useAccessControl';
import { EDossiers } from '../main-menu/menu.slice';
import BoundariesController from '../maritime-menu-options/boundaries-panel/boundaries-controller.utils';
import generateDatumRings from '../maritime-menu-options/incidents-panel/incident-datum/incident-datum';
import PopupHelpers from '../popup/popup.utils';
import { applyClientSideFilters } from '../utils/incidents-helpers';
import deriveMapObjectNameAndAction, {
  MapObjectInfo,
} from './map-click-actions';
import addCorrespondentsLayer from './map-layer-manager/correspondents-utils/add-correspondents-layer';
import setCorrespondentsFeatures from './map-layer-manager/correspondents-utils/set-correspondents-features';
import addFirstCallLayers from './map-layer-manager/first-call-utils/add-first-call-layer';
import setFirstCallFeatures from './map-layer-manager/first-call-utils/set-first-call-features';
import addIncidentDatumLayer from './map-layer-manager/incident-datum-utils/add-incident-datum-layer';
import addIncidentsLayer from './map-layer-manager/incident-utils/add-incidents-layer';
import loadIncidentTypeMarkerImages from './map-layer-manager/incident-utils/load-incident-type-marker-images';
import setIncidentFeatures from './map-layer-manager/incident-utils/set-incident-features';
import addIndustryNewsLayer from './map-layer-manager/industry-news-utils/add-industry-news-layer';
import setIndustryNewsFeatures from './map-layer-manager/industry-news-utils/set-industry-news-features';
import addBathymetryLayer from './map-layer-manager/layer-utils/add-bathymetry-layer';
import addRasterLayer from './map-layer-manager/layer-utils/add-raster-layer';
import MapLayerVisibility from './map-layer-manager/map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from './map-layer-manager/map-layer.enum';
import addMaritimeAreasLayer from './map-layer-manager/maritime-areas-utils/add-maritime-areas-layer';
import addPortsLayer from './map-layer-manager/port-utils/add-ports-layer';
import addRoutesIncidentsLayer from './map-layer-manager/route-utils/add-routes-incidents-layer';
import addRouteMaritimeAreasLayer from './map-layer-manager/route-utils/add-routes-maritime-areas-layer';
import addRoutesPortsLayer from './map-layer-manager/route-utils/add-routes-ports-layer';
import setVesselFocusRing from './map-layer-manager/vessel-utils/add-vessel-outline';
import addVesselsLayer from './map-layer-manager/vessel-utils/add-vessels-layer';
import addWeatherLayers from './map-layer-manager/weather-utils/add-weather-layers';
import MapHelpers, { ExtendedMapEvent } from './map.utils';

// NB: only use the mapbox web worker running Application builds
if (process.env.NODE_ENV === 'production') {
  // ^^ this is set to "production" automatically when application is built

  // @ts-ignore
  mapboxgl.workerClass =
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line global-require
}

interface MapProps {
  tickerOpen?: boolean;
}

function LayerSelectList({
  mapObjectInfo,
}: {
  mapObjectInfo: MapObjectInfo[];
}) {
  return (
    <List
      disablePadding
      sx={{
        overflow: 'auto',
        userSelect: 'none',
        padding: 0,
        margin: 0,
        height: '100%', // Ensures the List takes full height of its container
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {mapObjectInfo.map(({ id, name, action, type }, index) => (
        <ListItem
          key={id}
          divider
          disablePadding
          disableGutters
          sx={{
            margin: 0,
            borderBottom:
              index === mapObjectInfo.length - 1 ? 'none' : '0.9px solid',
          }}
        >
          <ListItemButton
            onClick={action}
            sx={{
              paddingY: '5px',
              paddingX: 0,
              margin: 0,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.2s',
              },
              borderBottomColor: 'rgba(0, 0, 0, 0.1)',
            }}
          >
            <ListItemText>
              <Typography variant="body1" style={{ fontSize: 14 }}>
                <b>{type.charAt(0).toUpperCase() + type.slice(1)}</b>
              </Typography>
              <Typography variant="body1" style={{ fontSize: 12 }}>
                <b>Name</b> {name}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function Map({ tickerOpen }: MapProps) {
  const MAP_CONTAINER_ID = 'map-container';
  const mapContainer = useRef<HTMLDivElement>(null);
  const prevLayouts = useRef<LayerProperty[]>([]);
  const intervalIdRef = useRef<NodeJS.Timer | null>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const style = 'mapbox://styles/geollectryan/clrah6383006f01qq4n9eapol';
  const mapExtent = useAppSelector((state) => state.map.mapExtent);
  const drawType = useAppSelector((state) => state.map.drawType);
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const paintProperty = useAppSelector((state) => state.map.paintProperty);
  const layoutProperty = useAppSelector((state) => state.map.layoutProperty);
  const flyTo = useAppSelector((state) => state.map.flyTo);
  const fitBounds = useAppSelector((state) => state.map.fitBounds);
  const centre = useAppSelector((state) => state.map.centre);
  const privileges = useAccessControl();

  const zoomToIncident = useAppSelector(
    (state) => state.userPreferences.userPreferences.zoomToIncident
  );

  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );
  const entitySelected =
    useAppSelector((state) => state.menu.selectedDossier) !== EDossiers.NOTHING;

  const dispatch = useAppDispatch();
  const draw = themedDraw;

  let map = useAppSelector((state) => state.map.map);
  const isMobile = useMobile();

  const { incidents, incidentTypes, selectedIncident } = useAppSelector(
    (state) => state.incidents
  );

  const { firstCallPorts } = useAppSelector((state) => state.firstCallPorts);

  const { correspondents } = useAppSelector((state) => state.correspondents);

  const { industryNews, selectedIndustryNews } = useAppSelector(
    (state) => state.industryNews
  );

  const { filters } = useAppSelector((state) => state.incidentsPanel);

  const { selectedVessels } = useAppSelector((state) => state.vesselDossier);

  const [clickPopupContent, setClickPopupContent] =
    useState<React.ReactNode | null>(null);
  const [clickPopupLngLat, setClickPopupLngLat] = useState<
    [number, number] | null
  >();

  const selectedBoundary = useAppSelector(
    (state) => state.boundaries.selectedBoundary
  );

  const selectedPort = useAppSelector((state) => state.ports.selectedPort);

  const loadAllLayers = useCallback(async () => {
    // TODO: Move all this to the map layer manager
    // Currently that causes a dependency cycle there's not the time to address now
    // I apologies for the logicbomb in the meantime
    if (!map.getLayer(MapLayer.FIRST_CALL_PORTS)) {
      addFirstCallLayers(MapLayer.FIRST_CALL_PORTS);
    }

    if (!map.getLayer(MapLayer.CORRESPONDENTS)) {
      addCorrespondentsLayer(MapLayer.CORRESPONDENTS);
    }

    if (!map.getLayer(MapLayer.INDUSTRY_NEWS)) {
      addIndustryNewsLayer(MapLayer.INDUSTRY_NEWS);
    }

    if (
      privileges.canAccessRiMaritimeAreas &&
      !map.getLayer(MapLayer.RI_MARITIME_AREAS)
    ) {
      await addMaritimeAreasLayer();
    }

    if (
      privileges.canAccessNearbyVessels &&
      !map.getLayer(MapLayer.NEARBY_VESSELS)
    ) {
      await addVesselsLayer(
        MapLayer.NEARBY_VESSELS,
        MapLayerVisibility.NOT_VISIBLE,
        undefined
      );
    }

    if (privileges.canAccessBathymetry && !map.getLayer(MapLayer.BATHYMETRY)) {
      await addBathymetryLayer(MapLayer.BATHYMETRY);
    }

    if (privileges.canAccessIncidents) {
      if (!map.getLayer(MapLayer.INCIDENTS)) {
        await addIncidentsLayer(MapLayer.INCIDENTS);
      }

      if (!map.getLayer(MapLayer.DATUM_RINGS)) {
        await addIncidentDatumLayer(MapLayer.DATUM_RINGS);
      }
    }

    if (privileges.canAccessMapTools && !map.getLayer(MapLayer.OSM_SEA)) {
      await addRasterLayer(MapLayer.OSM_SEA, [
        'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
      ]);
    }

    if (
      Object.values(privileges.canAccessPorts).includes(true) &&
      !map.getLayer(MapLayer.PORTS)
    ) {
      await addPortsLayer(MapLayer.PORTS);
    }

    if (privileges.canAccessWeather) {
      addWeatherLayers(map);
    }

    if (privileges.canAccessRoutes) {
      MapLayerManager.getLayerIdsFromSourceGroups([
        MapGroupLayer.ROUTES,
      ]).forEach((source) => {
        MapHelpers.setLayerVisibility(source, false);
      });

      if (privileges.canAccessRiMaritimeAreas) {
        await addRouteMaritimeAreasLayer(map);
      }

      if (
        Object.values(privileges.canAccessPorts).includes(true) &&
        !map.getLayer(MapLayer.ROUTES_PORTS)
      ) {
        await addRoutesPortsLayer(
          MapLayer.ROUTES_PORTS,
          MapLayerManager.groupLayerMetaData([MapGroupLayer.ROUTES])
        );
      }
      if (privileges.canAccessIncidents) {
        await addRoutesIncidentsLayer(
          map,
          MapLayerManager.groupLayerMetaData([MapGroupLayer.ROUTES])
        );
      }
    }

    if (privileges.canAccessBoundaries) {
      await BoundariesController.AddCombinedBoundariesLayers();
    }
  }, [privileges]);

  useEffect(() => {
    // We need to be able to load layers at a later point once a user's token has been retrieved
    // i.e. they've logged in.
    // This allows us to perform access control around initialising layers.
    if (mapLoaded) {
      if (!imagesLoaded) {
        MapLayerManager.loadMapImages();
        setImagesLoaded(true);
      } else {
        loadAllLayers()
          .then(() => {
            dispatch(setMapInitialised(true));
          })
          .then(() => {
            // We want draw vertex handles to render above the lines.
            // map.moveLayer with no second arg moves the layer to the top.
            map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.cold');
            map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.hot');
          });
      }
    }
  }, [mapLoaded, imagesLoaded, loadAllLayers]);

  useEffect(() => {
    if (mapInitialised) {
      const leftPadding = MapHelpers.calculateLeftCameraPadding(
        secondaryMenuOpen,
        entitySelected
      );
      map.setPadding({ top: 0, left: leftPadding, right: 0, bottom: 0 });
    }
  }, [secondaryMenuOpen, entitySelected, mapInitialised]);

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    // use map instance if it exists
    if (map?.getContainer?.()) {
      mapContainer.current.replaceWith(map.getContainer());
      map.resize();
      return;
    }

    dispatch(setMapInitialised(false));
    map = new mapboxgl.Map({
      // ideally we would use ref for this, but TS flags this as an error here since ref values can potentially be null...
      container: MAP_CONTAINER_ID,
      accessToken: MAPBOX_ACCESS_TOKEN,
      style,
      logoPosition: isMobile ? 'bottom-left' : 'bottom-right',
      ...mapExtent,
    });
    const onClick = async (
      event: (mapboxgl.MapTouchEvent | mapboxgl.MapMouseEvent) &
        mapboxgl.EventData
    ) => {
      const { drawType: dt } = store.getState().map;

      /**
       * ############################################
       * Check if the event is a drawing event.
       *
       * if it is flag the event as a drawing event so that
       * the layer click handler can ignore it.
       * ############################################
       */

      // eslint-disable-next-line no-param-reassign
      (event as ExtendedMapEvent).isDrawing =
        draw.getMode() !== 'simple_select' ||
        ['draw_end', 'box', 'boundary', 'polygon'].includes(dt!) ||
        false;

      if (dt === 'draw_end') {
        dispatch(setDrawType(null));
      }

      // ############################################

      if (dt !== null || draw.getMode() !== 'simple_select') return;

      const countryFeature = await MapHelpers.countryUnderMouse(event);

      const bufferedPoint = MapHelpers.bufferPoint(event.point, { percent: 2 });

      const features = map.queryRenderedFeatures(bufferedPoint, {
        layers: [
          MapLayer.INCIDENTS,
          MapLayer.INCIDENT_CLUSTERS,
          MapLayer.ROUTES_INCIDENTS,
          MapLayer.ROUTES_INCIDENT_CLUSTERS,
          MapLayer.RI_MARITIME_AREAS,
          MapLayer.PORTS,
          MapLayer.PORT_CLUSTERS,
          MapLayer.ROUTES_PORTS,
          MapLayer.ROUTES_PORT_CLUSTERS,
          MapLayer.FIRST_CALL_PORTS,
          MapLayer.FIRST_CALL_CLUSTERS,
          MapLayer.CORRESPONDENTS,
          MapLayer.CORRESPONDENT_CLUSTERS,
          MapLayer.INDUSTRY_NEWS,
          MapLayer.INDUSTRY_NEWS_CLUSTERS,
        ].filter((layer) => map.getLayer(layer)),
      });

      const closeAction = () => {
        setClickPopupContent(null);
        setClickPopupLngLat(null);
      };
      if (features.length) {
        const mapObjectInfo = features.map((feature) =>
          deriveMapObjectNameAndAction(event, feature, dispatch, closeAction)
        );
        // clusters for some reason return 2 results per cluster, so we need to filter out duplicates
        const uniqueMapObjects = mapObjectInfo.filter(
          // shorthand for unique by id
          (val, index, array) =>
            array.findIndex((item) => item.id === val.id) === index
        );

        // if there is only one entry in the array, there is no ambiguity in which item was clicked.
        // so we can just execute the action.
        if (uniqueMapObjects.length === 1) {
          uniqueMapObjects[0].action();
          return;
        }
        // if there are multiple entries, we need to display a list of options to the user.
        if (countryFeature) {
          const countryAction = deriveMapObjectNameAndAction(
            event,
            countryFeature,
            dispatch,
            closeAction
          );
          uniqueMapObjects.push(countryAction);
        }
        setClickPopupLngLat([event.lngLat.lng, event.lngLat.lat]);
        setClickPopupContent(
          <LayerSelectList mapObjectInfo={uniqueMapObjects} />
        );
      } else if (countryFeature) {
        const countryAction = deriveMapObjectNameAndAction(
          event,
          countryFeature,
          dispatch,
          closeAction
        );
        countryAction.action();
      } else {
        setClickPopupContent(null);
        setClickPopupLngLat(null);
      }
    };

    const tooltipLayers = [
      MapLayer.INCIDENTS,
      MapLayer.ROUTES_INCIDENTS,
      MapLayer.RI_MARITIME_AREAS,
      MapLayer.PORTS,
      MapLayer.ROUTES_PORTS,
      MapLayer.FIRST_CALL_PORTS,
      MapLayer.CORRESPONDENTS,
      MapLayer.INDUSTRY_NEWS,
    ];

    const popup = new Popup({
      closeButton: false,
      closeOnMove: true,
    });

    const popupContent: { label: string; value: any }[] = [];

    const onMouseEnter = async (
      layer: MapLayer,
      e: mapboxgl.MapLayerMouseEvent
    ) => {
      PopupHelpers.generateLayerPopupContent(layer, e, popupContent, popup);
    };

    const onMouseLeave = async (
      layer: MapLayer,
      e: mapboxgl.MapLayerMouseEvent
    ) => {
      popupContent.length = 0;
      PopupHelpers.deleteLayerPopupContent(layer, e, popup);
    };

    tooltipLayers.forEach((layerId) => {
      map.on('mouseenter', layerId, (e: MapLayerMouseEvent) => {
        onMouseEnter(layerId, e);
      });
    });

    tooltipLayers.forEach((layerId) => {
      map.on('mouseleave', layerId, (e: MapLayerMouseEvent) => {
        onMouseLeave(layerId, e);
      });
    });

    if (!isMobile) {
      map.addControl(
        new mapboxgl.ScaleControl({ unit: 'metric' }),
        'bottom-right'
      );
      map.addControl(
        new mapboxgl.ScaleControl({ unit: 'imperial' }),
        'bottom-right'
      );
    }

    // whenever map style is changed from map.setStyle, all map icon images are removed
    // store images in a "cache" so that icons can be re-loaded
    map.on('data', (e: mapboxgl.MapStyleDataEvent) => {
      if (e.dataType === 'style') {
        updateMapImageCache(e);
      }
    });

    // TODO: possibly move this into a Widget/Control Manager.
    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: !isMobile,
      }),
      'bottom-right'
    );

    map.on('zoom', () => {
      dispatch(setMapZoom(map.getZoom()));
    });

    map.on('mousemove', (e) => {
      const updateMapDetailsIndicator = (coords: string) => {
        const element = document.getElementById(
          'map-details-indicator-container'
        );
        if (element) {
          element.innerHTML = `<div id="map-details-indicator">${coords}</div>`;
        }
      };

      if (map.isPointOnSurface(e.point)) {
        // Longitude wrapped to the range (-180, 180).
        const wrappedLngLat = e.lngLat.wrap();
        const { lng, lat } = wrappedLngLat;

        // Take the absolute value of latitude while indicating the proper N/S direction.
        // Take the absolute value of longitude while indicating the proper E/W direction.
        const dms = latLngDecimalToDMS(lat, lng);

        updateMapDetailsIndicator(dms);
      } else {
        updateMapDetailsIndicator('-');
      }
    });

    dispatch(setMap(map));
    dispatch(setMapZoom(map.getZoom()));

    map.on('load', () => {
      map.boxZoom.enable();
      setMapLoaded(true);
    });

    // prevent touch-then-drag being interpreted as a click
    // if start point is significantly different from end point, or 250ms elapses, then it's a drag
    let isClick = false;
    let point: { x: number; y: number } | null = null;

    map.on('touchstart', (e) => {
      isClick = true;
      point = e.point;
      setTimeout(() => {
        isClick = false;
        point = null;
      }, 250);
    });

    map.on('touchend', (e) => {
      if (isClick && GeoHelper.pointsNear(point, e.point)) onClick(e);
    });

    map.on('click', (e) => onClick(e));
    map.on('error', ({ error }) => {
      // double-adding of images can be ignored. Pass any others
      if (
        !error.message.startsWith('An image with this name already exists.')
      ) {
        throw error;
      }
    });

    map.on('styleimagemissing', (e) => {
      loadCachedMapImage(e.id);
    });

    // Add a custom moveend listener. If there is an orginalEvent, then it's a user moveend.
    // Otherwise it is a flyend.
    map.on('moveend', ({ originalEvent }) => {
      if (originalEvent) {
        map.fire('usermoveend');
      } else {
        map.fire('flyend');
      }
    });

    map.dragRotate.disable();

    addCustomMapControls(draw);

    // @ts-ignore
    window.map = map;
  }, [isMobile]);

  useEffect(() => {
    if (mapInitialised) {
      setMapDrawControlsState(privileges);
    }
  }, [mapInitialised, privileges]);

  useEffect(() => {
    if (mapInitialised && map && paintProperty!.layerId) {
      map.setPaintProperty(
        paintProperty!.layerId!,
        paintProperty!.name,
        paintProperty!.value
      );
    }
  }, [mapInitialised, paintProperty]);

  const getArrayDifferences = (
    newLayoutArray: any[],
    prevLayoutArray: any[]
  ): any[] =>
    newLayoutArray.filter((item1) => {
      const matchingItem = prevLayoutArray.find(
        (item2) => item1.layerId === item2.layerId
      );
      return (
        !matchingItem || JSON.stringify(item1) !== JSON.stringify(matchingItem)
      );
    });

  useEffect(() => {
    if (layoutProperty) {
      const changedLayers = getArrayDifferences(
        layoutProperty,
        prevLayouts.current
      );
      changedLayers.forEach((layout) => {
        map.setLayoutProperty(layout.layerId, layout.name, layout.value);
        MapHelpers.triggerLayerVisibilityChange(layout.layerId);
      });
      prevLayouts.current = layoutProperty;
    }
  }, [layoutProperty]);

  useEffect(() => {
    if (mapInitialised && centre) {
      map.setCenter(centre);
    }
  }, [centre]);

  useEffect(() => {
    if (mapInitialised && flyTo) {
      map.flyTo(flyTo);
    }
  }, [flyTo]);

  useEffect(() => {
    /*
     * Bug in mapbox, using fitBounds after a flyTo adds their paddings together
     * which can cause there to be less screen than padding, which causes fitbounds to fail.
     * See https://github.com/mapbox/mapbox-gl-js/issues/11831
     * Workaround: setPadding back to 0 manually.
     */
    if (fitBounds.bounds) {
      /* setting the padding in a seperate hook wasn't working properly
       * assumption is that the padding is always getting this value was
       * fitBounds is being set since that's what was happening, may need
       * to change in the future
       */
      map.setPadding({ top: 0, bottom: 0, left: 0, right: 0 });
      map.fitBounds(fitBounds.bounds, {
        ...fitBounds.options,
        padding: isMobile
          ? map.getContainer().clientWidth * 0.15
          : fitBounds.options?.padding,
      });
    }
  }, [fitBounds]);

  useEffect(() => {
    if (mapInitialised && incidentTypes) {
      const setMapIncidentFeatures = async () => {
        await loadIncidentTypeMarkerImages(incidentTypes);
        setIncidentFeatures(
          MapLayer.INCIDENTS,
          applyClientSideFilters(filters, Object.values(incidents?.byId ?? {}))
        );
      };
      setMapIncidentFeatures();
    }
  }, [incidents, selectedIncident, mapInitialised, incidentTypes, map]);

  useEffect(() => {
    if (firstCallPorts && mapInitialised) {
      setFirstCallFeatures(firstCallPorts);
    }
  }, [firstCallPorts, mapInitialised]);

  useEffect(() => {
    if (correspondents && mapInitialised) {
      setCorrespondentsFeatures(MapLayer.CORRESPONDENTS, correspondents);
    }
  }, [correspondents, mapInitialised]);

  useEffect(() => {
    if (industryNews && mapInitialised) {
      setIndustryNewsFeatures(MapLayer.INDUSTRY_NEWS, industryNews);
    }
  }, [industryNews, mapInitialised]);

  useEffect(() => {
    if (
      mapInitialised &&
      selectedIndustryNews?.lng &&
      selectedIndustryNews.lat
    ) {
      MapHelpers.zoomToPoint(
        [selectedIndustryNews.lng, selectedIndustryNews.lat],
        8.5
      );
    }
  }, [selectedIndustryNews, mapInitialised]);

  // this ensures setInterval within 'generateDatumRings' only runs one at a time
  useEffect(() => {
    if (incidents && mapInitialised) {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      intervalIdRef.current = generateDatumRings(incidents);
    }
  }, [incidents]);

  useEffect(() => {
    if (mapInitialised && map && selectedIncident?.position && zoomToIncident) {
      MapHelpers.zoomToPointFast(
        [
          selectedIncident.position.longitude,
          selectedIncident.position.latitude,
        ],
        {
          zoom: 7,
        }
      );
    }
  }, [incidents, selectedIncident, mapInitialised, map]);

  useEffect(() => {
    if (mapInitialised && map && selectedPort) {
      MapHelpers.zoomToPointFast([
        parseFloat(selectedPort.LONG),
        parseFloat(selectedPort.LAT),
      ]);
    }
  }, [selectedPort, mapInitialised, map]);

  useEffect(() => {
    if (mapInitialised && (selectedVessels?.allIds?.length || 0) > 0) {
      setVesselFocusRing(
        MapLayer.VESSEL_FOCUS_RING,
        Object.values(selectedVessels?.byId || {})
      );
    }
  }, [selectedVessels, mapInitialised]);

  // zoom to selected boundary and show it on the map
  useEffect(() => {
    if (selectedBoundary) {
      const {
        format,
        geojson,
        boundary_source_layer: layerId,
      } = selectedBoundary;
      if (format === 'geojson' && geojson) {
        MapHelpers.zoomToFeatureCollection(geojson);
        MapHelpers.setLayerVisibility(layerId, true);
        MapHelpers.setLayerVisibility(`${layerId}_line`, true);
        MapHelpers.setLayerVisibility(`${layerId}_fill`, true);
        return;
      }

      const layerSource = MapHelpers.getSource(layerId) as VectorSource;
      const bounds = layerSource?.bounds;

      if (bounds) {
        MapHelpers.zoomToLine([bounds[0], bounds[1]], [bounds[2], bounds[3]]);
        MapHelpers.setLayerVisibility(layerId, true);
      }
    }
  }, [selectedBoundary]);

  return (
    <>
      {clickPopupLngLat && (
        <PopupBase lngLat={clickPopupLngLat} anchor="left" closeButton={false}>
          {clickPopupContent}
        </PopupBase>
      )}
      <div
        id={MAP_CONTAINER_ID}
        ref={mapContainer}
        data-testid={MAP_CONTAINER_ID}
        className={classNames({
          tickerOpen,
          'boundary-select':
            drawType === 'boundary' ||
            drawType === 'box' ||
            drawType === 'polygon',
        })}
      />
    </>
  );
}

Map.defaultProps = {
  tickerOpen: false,
};

export default Map;
