export const AWS_REGION = process.env.REACT_APP_AWS_REGION;

// Auth
export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
export const AWS_USER_POOL_WEBCLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID;
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const SIGN_IN_REDIRECT_URL = process.env.REACT_APP_SIGN_IN_REDIRECT_URL;
export const SIGN_OUT_REDIRECT_URL =
  process.env.REACT_APP_SIGN_OUT_REDIRECT_URL;
export const NORTH_STANDARD_IDP_NAME =
  process.env.REACT_APP_NORTH_STANDARD_IDP_NAME;
export const USE_HOSTED_UI =
  process.env.REACT_APP_ALLOW_INTERNAL_USERS === 'true';

// APIs
export const GEONIUS_BACKEND_URL_ROOT =
  process.env.REACT_APP_GEONIUS_BACKEND_URL;
export const NS_BACKEND_URL = process.env.REACT_APP_NS_BACKEND_URL;

// Storage
export const ENTITY_DOCUMENTS_BUCKET =
  process.env.REACT_APP_ENTITY_DOCUMENTS_BUCKET_NAME;

// roles
export const STAFF_USER_ROLE =
  process.env.REACT_APP_STAFF_USER_ROLE || 'Unknown';
export const MEMBER_USER_ROLE =
  process.env.REACT_APP_MEMBER_USER_ROLE || 'Unknown';

// mapbox
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
