/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import mapboxgl, {
  LngLatBoundsLike,
  LngLatLike,
  PaddingOptions,
} from 'mapbox-gl';
import MapLayer from './map-layer-manager/map-layer.enum';
import MapStyle from './map-style';

export const DEFAULT_MAP_CENTER: [number, number] = [-4, 54];
export const DEFAULT_MAP_ZOOM = 2;

export type MapExtent = {
  center: [number, number];
  zoom: number;
};

export type MapStyleObject = {
  style: any;
  options: { diff?: boolean };
};

export type FitBounds = {
  bounds: LngLatBoundsLike | null;
  options?: mapboxgl.FitBoundsOptions;
};

export type LayerProperty = {
  layerId: MapLayer | null;
  name: string;
  value: any;
} | null;

interface InitialState {
  map: mapboxgl.Map;
  mapExtent: MapExtent;
  mapZoom: number;
  bathymetryOpacity: number;
  mapInitialised: boolean;
  style: string;
  drawType: string | null;
  mapStyle: MapStyleObject;
  paintProperty: LayerProperty;
  layoutProperty: { layerId: MapLayer; name: string; value: any }[];
  flyTo: {};
  padding: PaddingOptions;
  fitBounds: FitBounds;
  centre: LngLatLike | null;
}

export const INITIAL_MAP_STATE: InitialState = {
  map: {} as mapboxgl.Map,
  mapExtent: {
    center: DEFAULT_MAP_CENTER,
    zoom: DEFAULT_MAP_ZOOM,
  },
  mapZoom: 2,
  bathymetryOpacity: 100,
  mapInitialised: false,
  style: MapStyle.DEFAULT,
  drawType: null,
  mapStyle: {
    style: null,
    options: { diff: true },
  },
  paintProperty: {
    layerId: null,
    name: '',
    value: 0,
  },
  layoutProperty: [],
  flyTo: {},
  padding: { top: 0, left: 0, right: 0, bottom: 0 },
  fitBounds: {
    bounds: null,
  },
  centre: null,
};

const MapSlice = createSlice({
  name: 'map',
  initialState: INITIAL_MAP_STATE,
  reducers: {
    setStyle: (state, action) => {
      state.style = action.payload;
    },
    setMap: (state, action: PayloadAction<mapboxgl.Map>) => {
      state.map = action.payload;
    },
    setMapInitialised: (state, action) => {
      state.mapInitialised = action.payload;
    },
    setMapExtent: (state, action) => {
      state.mapExtent = action.payload;
    },
    setDrawType: (state, action) => {
      state.drawType = action.payload;
    },
    setMapZoom: (state, action) => {
      state.mapZoom = action.payload;
    },
    setBathymetryOpacity: (state, action) => {
      state.bathymetryOpacity = action.payload;
    },
    setMapStyle: (
      state,
      action: PayloadAction<{ style: any | null; options?: { diff?: boolean } }>
    ) => {
      state.mapStyle = {
        style: action.payload.style,
        options: action.payload.options || { diff: true },
      };
    },
    setPaintProperty: (state, action) => {
      state.paintProperty = action.payload;
    },
    setLayoutProperty: (state, action) => {
      const existingIndex = state.layoutProperty.findIndex(
        (item) => item.layerId === action.payload.layerId
      );

      if (existingIndex !== -1) {
        // If layerId already exists, update the existing item
        state.layoutProperty = state.layoutProperty.map((item, index) =>
          index === existingIndex
            ? {
                layerId: action.payload.layerId,
                name: action.payload.name,
                value: action.payload.value,
              }
            : item
        );
      } else {
        // If layerId doesn't exist, add a new item
        state.layoutProperty = [
          ...state.layoutProperty,
          {
            layerId: action.payload.layerId,
            name: action.payload.name,
            value: action.payload.value,
          },
        ];
      }
    },
    setFlyTo: (state, action) => {
      state.flyTo = action.payload;
    },
    setPadding: (state, action) => {
      state.padding = action.payload;
    },
    setFitBounds: (state, action) => {
      state.fitBounds = action.payload;
    },
    setCentre: (state, action) => {
      state.centre = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setStyle,
  setMap,
  setMapZoom,
  setBathymetryOpacity,
  setMapInitialised,
  setMapExtent,
  setDrawType,
  setMapStyle,
  setPaintProperty,
  setLayoutProperty,
  setFlyTo,
  setPadding,
  setFitBounds,
  setCentre,
} = MapSlice.actions;

export default MapSlice.reducer;
