import { Position } from '@turf/helpers';
import axios from 'axios';

import * as turf from '@turf/turf';
// @ts-ignore
import dobbyscan from 'dobbyscan';
import { AnySourceData, GeoJSONSource } from 'mapbox-gl';
import { DrawingShape } from '../models/drawings.model';

import { MAPBOX_ACCESS_TOKEN } from '../environment';
import MapLayer from '../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../map/map.utils';
import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import {
  Incident,
  MapIncident,
} from '../maritime-menu-options/incidents-panel/incident.model';
import { Port } from '../maritime-menu-options/world-ports-panel/world-ports.model';
import { Correspondent } from '../models/correspondents.model';
import { IndustryNews } from '../models/industry-news.model';
import { Vessel } from '../models/vessel.model';
import { getIncidentMapBoxImageName } from './incidents-helpers';
import { positionDefined } from './typescript-helpers';

interface MapboxGeocodeFeature extends GeoJSON.Feature {
  id: string;
  place_type: string[];
  relevance: number;
  properties: {
    wikidata: string;
    short_code: string;
    mapbox_id: string;
  };
  text: string;
  place_name: string;
  matching_text: string;
  matching_place_name: string;
  bbox: [number, number, number, number];
  center: [number, number];
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
}
interface MapboxGeocodeReturn extends GeoJSON.FeatureCollection {
  query: string[];
  features: MapboxGeocodeFeature[];
}

namespace GeoHelper {
  export const MAPBOX_API_URL = 'https://api.mapbox.com/v4';

  export type Rectangle = [
    GeoJSON.Position,
    GeoJSON.Position,
    GeoJSON.Position,
    GeoJSON.Position,
    GeoJSON.Position
  ];

  export const validateLatitude = (latitude: number) =>
    Math.abs(latitude) <= 90;
  export const validateLongitude = (longitude: number) =>
    Math.abs(longitude) <= 180;

  export type Circle = {
    radius: number;
    coordinates: GeoJSON.Position;
  };

  export const createFeaturePoint = (
    coordinates: GeoJSON.Position,
    // [longitude, latitude]
    properties: GeoJSON.GeoJsonProperties
  ): GeoJSON.Feature<GeoJSON.Point> => ({
    type: 'Feature',
    properties: {
      subType: DrawingShape.Point,
      ...properties,
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  });

  export const createFeatureLineString = (
    coordinates: GeoJSON.Position[],
    properties: GeoJSON.GeoJsonProperties
  ): GeoJSON.Feature<GeoJSON.LineString> => ({
    type: 'Feature',
    properties: {
      subType: DrawingShape.Line,
      ...properties,
    },
    geometry: {
      type: 'LineString',
      coordinates,
    },
  });

  export const cleanAISFeatureHistoryLineString = (
    feature: GeoJSON.Feature<GeoJSON.LineString>
  ): GeoJSON.Feature<GeoJSON.LineString> => {
    const { coordinates } = feature.geometry;
    const cleanedCoordinates = coordinates.filter(
      (coordinate) =>
        validateLongitude(coordinate[0]) && validateLatitude(coordinate[1])
    );
    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: cleanedCoordinates,
      },
    };
  };

  /**
   * Turns an extent into a rectangle. The extent is an array of 4 numbers,
   * [minLon, maxLon, minLat, maxLat]
   * @param extent
   * @returns points representing the four corners of the rectangle
   * NOTE: this does not close the polygon, the first and last points are not the same.
   * This is the format needed by mapbox for the four corners of a raster layer, for example
   */
  export const extentToRect = (
    extent: [number, number, number, number]
  ): [number, number][] => {
    const [minLon, maxLon, minLat, maxLat] = extent;
    return [
      [minLon, minLat],
      [maxLon, minLat],
      [maxLon, maxLat],
      [minLon, maxLat],
    ];
  };

  export const createRectangle = (
    p1: GeoJSON.Position,
    p2: GeoJSON.Position
  ): Rectangle => {
    const topLeftX = Math.min(p1[0], p2[0]);
    const topLeftY = Math.max(p1[1], p2[1]);
    const bottomRightX = Math.max(p1[0], p2[0]);
    const bottomRightY = Math.min(p1[1], p2[1]);
    return [
      [topLeftX, topLeftY],
      [bottomRightX, topLeftY],
      [bottomRightX, bottomRightY],
      [topLeftX, bottomRightY],
      [topLeftX, topLeftY],
    ];
  };

  export const createCircle = (
    radius: number,
    centreX: number,
    centreY: number
  ): Circle => ({
    radius,
    coordinates: [centreX, centreY],
  });

  export const createCircumferenceCoordinates = (
    circle: Circle,
    resolution: number = 60
  ): GeoJSON.Position[] => {
    // Rough conversion factors
    const longFactor = 111.32;
    const latFactor = 110.574;
    const [longitude, latitude] = circle.coordinates;
    const longRadius =
      circle.radius / (longFactor * Math.cos((latitude * Math.PI) / 180));
    const latRadius = circle.radius / latFactor;
    const coordinates = Array.from({ length: resolution + 1 }, (_, i) => {
      const degree = (i / resolution) * (2 * Math.PI);
      const longOffset = longRadius * Math.cos(degree);
      const latOffset = latRadius * Math.sin(degree);
      return [longitude + longOffset, latitude + latOffset];
    });
    return coordinates;
  };

  export const createFeaturePolygon = (
    polygonCoordinates: Position[][],
    properties: GeoJSON.GeoJsonProperties
  ): GeoJSON.Feature<GeoJSON.Polygon> => ({
    type: 'Feature',
    properties: {
      subType: DrawingShape.Polygon,
      ...properties,
    },
    geometry: {
      type: DrawingShape.Polygon,
      coordinates: polygonCoordinates,
    },
  });

  // TODO: Revisit this. Commented out incase RI want perfect Squares & Rectangles
  // export const createFeatureRectangle = (
  //   coordinates: Rectangle,
  //   properties: GeoJSON.GeoJsonProperties
  // ): GeoJSON.Feature<GeoJSON.Polygon> =>
  //   createFeaturePolygon([coordinates], {
  //     ...properties,
  //     subType: DrawingShape.Rectangle,
  //   });

  export const convertFeatureCircleToPolygon = (
    featureCircle: GeoJSON.Feature<GeoJSON.Point>
  ): GeoJSON.Feature<GeoJSON.Polygon> => {
    const circlePolygon = turf.circle(
      [
        featureCircle.geometry.coordinates[0],
        featureCircle.geometry.coordinates[1],
      ],
      featureCircle.properties?.radius ?? 0,
      { steps: 60, units: 'kilometers' }
    );

    return createFeaturePolygon(circlePolygon.geometry.coordinates, {
      ...featureCircle.properties,
    });
  };

  export const createFeatureCircle = (
    circle: Circle,
    properties: GeoJSON.GeoJsonProperties // radius is a required property for circles
  ): GeoJSON.Feature<GeoJSON.Point> =>
    createFeaturePoint(circle.coordinates, {
      ...properties,
      subType: DrawingShape.Circle,
      radius: circle.radius,
    });

  export const createGeoJSON = (
    features: GeoJSON.Feature[] = []
  ): GeoJSON.FeatureCollection => ({
    type: 'FeatureCollection',
    features,
  });

  export function addSimpleGeoJsonSource(
    layer: MapLayer | string,
    clustering = false
  ): GeoJSONSource {
    let options: AnySourceData = {
      type: 'geojson',
      data: GeoHelper.createGeoJSON(),
      // create unique ids for each feature
      generateId: true,
    };
    if (clustering) {
      options = {
        ...options,
        cluster: true,
        clusterMaxZoom: 4, // Max zoom to cluster points on
        clusterRadius: 60,
        clusterMinPoints: 4,
      };
    }

    if (MapHelpers.getSource(layer)) {
      MapHelpers.deleteSource(layer);
    }
    MapHelpers.addSource(layer, options);

    return MapHelpers.getSource(layer) as GeoJSONSource;
  }

  export const vesselsToGeoJSONFeatures = (
    vessels: Vessel[]
  ): GeoJSON.Feature<GeoJSON.Point>[] =>
    vessels.map((vessel: Vessel) => {
      const { longitude, latitude, ...properties } = vessel;

      return GeoHelper.createFeaturePoint([longitude, latitude], properties);
    });

  export const incidentsToGeoJSONFeatures = (
    incidents: Incident[]
  ): GeoJSON.Feature<GeoJSON.Point>[] =>
    incidents
      // Remove incidents without a position (RI API suggests this is possible)
      .filter((incident): incident is MapIncident => positionDefined(incident))
      .map((incident) => {
        const { position, ...properties } = incident;

        return GeoHelper.createFeaturePoint(
          [position.longitude, position.latitude],
          {
            title: properties.title,
            type: properties.type,
            region: properties.region,
            date: properties.date,
            id: properties.id,
            icon: {
              base: getIncidentMapBoxImageName(incident.type, 'marker'),
              selected: getIncidentMapBoxImageName(incident.type, 'markerAura'),
              alerting: getIncidentMapBoxImageName(
                incident.type,
                'markerAlert'
              ),
              selectedAlerting: getIncidentMapBoxImageName(
                incident.type,
                'markerAlertAura'
              ),
            },
            selected: properties.selected,
            alert: properties.alert,
          }
        );
      });

  export const portsToGeoJSONFeatures = (
    ports: Port[]
  ): GeoJSON.Feature<GeoJSON.Point>[] =>
    ports.map((port) => {
      const { LAT, LONG } = port;
      // Not all information in port is required in the mapbox properties

      const { selected, ri, WPI, NAME, UNLOCODE, COUNTRY } = port;

      return GeoHelper.createFeaturePoint(
        [Number.parseFloat(LONG), Number.parseFloat(LAT)],
        {
          selected,
          WPI,
          NAME,
          COUNTRY,
          UNLOCODE,
          // don't need the RI object, just whether it's defined or not
          ri: !!ri,
          inland: ri?.inland,
          cruise: ri?.cruise,
        }
      );
    });

  export const correspondentsToGeoJSONFeatures = (
    correspondents: Correspondent[]
  ): GeoJSON.Feature<GeoJSON.Point>[] =>
    correspondents.map((correspondent) => {
      const { lat, lng } = correspondent.port_information.location;
      // Not all information in port is required in the mapbox properties

      const { selected } = correspondent;

      return GeoHelper.createFeaturePoint([lng!, lat!], {
        selected,
        ...correspondent,
      });
    });

  export const industryNewsToGeoJSONFeatures = (
    indsutryNews: IndustryNews[]
  ): GeoJSON.Feature<GeoJSON.Point>[] =>
    indsutryNews.map((news) => {
      const { lat, lng: lon, selected } = news;

      return GeoHelper.createFeaturePoint([lon!, lat!], {
        selected,
        ...news,
      });
    });

  export interface TimeGap extends Pick<Vessel, 'mmsi' | 'imo' | 'name'> {
    id: number;
    startSpeed: number;
    endSpeed: number;
    startTimestamp: number;
    endTimestamp: number;
    duration: number;
    uniqueId: string;
  }

  export type TimeGapFeature = GeoJSON.Feature<GeoJSON.LineString, TimeGap>;

  // gap length in milliseconds - 1 day by default
  export const generateTimeGapData = (
    points: HistoricVesselPoint[],
    gapLength: number = 86400000
  ) => {
    const gapLines = [];

    for (let i = 0; i < points.length - 1; i += 1) {
      const firstPoint = points[i];
      const nextPoint = points[i + 1];

      if (firstPoint.mmsi === nextPoint.mmsi) {
        const firstPointTime =
          typeof firstPoint.timestamp === 'number'
            ? firstPoint.timestamp
            : Date.parse(String(firstPoint.timestamp));

        const nextPointTime =
          typeof nextPoint.timestamp === 'number'
            ? nextPoint.timestamp
            : Date.parse(String(nextPoint.timestamp));

        const timeDifference = Math.abs(nextPointTime - firstPointTime);

        if (timeDifference > gapLength) {
          gapLines.push(
            GeoHelper.createFeatureLineString(
              [
                [firstPoint.longitude, firstPoint.latitude],
                [nextPoint.longitude, nextPoint.latitude],
              ],
              {
                id: gapLines.length,
                mmsi: firstPoint.mmsi,
                name: firstPoint.name,
                imo: firstPoint.imo,
                startSpeed: firstPoint.speed,
                endSpeed: nextPoint.speed,
                startTimestamp: firstPointTime,
                endTimestamp: nextPointTime,
                duration: timeDifference,
                uniqueId: firstPoint.vessel_id,
              }
            )
          );
        }
      }
    }

    return GeoHelper.createGeoJSON(gapLines) as GeoJSON.FeatureCollection<
      GeoJSON.LineString,
      TimeGap
    >;
  };

  // returns group of features found in clusters
  export const mergeGeoJSONFeatures = (
    features: GeoJSON.Feature[]
  ): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[][] => {
    // Distance in KM
    const MAX_DISTANCE = 0.00000001;
    const clustersRaw = dobbyscan(
      features,
      MAX_DISTANCE,
      (p: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>) =>
        p.geometry.coordinates[0],
      (p: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>) =>
        p.geometry.coordinates[1]
    );

    const clustersFiltered = clustersRaw.filter(
      (arr: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]) =>
        arr.length > 1
    );
    return clustersFiltered;
  };

  // Point in Polygon Queries
  // https://docs.mapbox.com/api/maps/tilequery/
  export const getFeaturesInVectorTiles = (
    // minimum of one tileset ID
    tilesetIds: string[],
    // longitude, latitude
    coordinates: [number, number]
  ) => {
    // mapbox tilequery API only allows 15 tilesets to be queried at one time
    const MAX_TILESETS_PER_REQUEST = 15;
    const REQUESTS_COUNT = Math.ceil(
      tilesetIds.length / MAX_TILESETS_PER_REQUEST
    );

    const defaultQueryParams = {
      access_token: MAPBOX_ACCESS_TOKEN,
      // return max of 1 feature per layer
      limit: `${MAX_TILESETS_PER_REQUEST}`,
    };

    const promises = [];

    // split tile queries into multiple requests to not exceed max tileset query limit
    for (let count = 0; count < REQUESTS_COUNT; count += 1) {
      const startIndex = count * MAX_TILESETS_PER_REQUEST;
      const endIndex = startIndex + MAX_TILESETS_PER_REQUEST;

      const tilesToQuery = tilesetIds.slice(startIndex, endIndex);
      const tilesQueryString = tilesToQuery.join(',');
      const coordinatesQueryString = coordinates.join(',');

      const URL = `${MAPBOX_API_URL}/${tilesQueryString}/tilequery/${coordinatesQueryString}.json`;

      // sample request:
      // GET https://api.mapbox.com/v4/russia_eez,iran_eez/tilequery/0,0.json?access_token=ACCESS_TOKEN_HERE&limit=15
      const promise = axios
        .get(`${URL}`, { params: defaultQueryParams })
        .then(
          (response) => (response.data as GeoJSON.FeatureCollection).features
        );

      promises.push(promise);
    }

    return Promise.all(promises).then((responses) => responses.flat());
  };

  // turf buffers get capped to -180 to 180, but mapbox allows -360 to 360.
  // crossing the antimeridian in 180limit, causes lines/polygons to be drawn on the wrong side of the map in 360limit.
  // this function converts the coordinates to 360limit
  const coords180to360 = (coords: GeoJSON.Position[]) => {
    let offset = 0;
    const newCoords = coords.map(([long, lat], index) => {
      if (index === 0) {
        return [long, lat];
      }
      const prevLong: number = coords[index - 1][0];
      const diff = prevLong - long;
      if (diff > 180) {
        offset += 1;
      }
      if (diff < -180) {
        offset -= 1;
      }
      return [long + 360 * offset, lat];
    });
    return newCoords;
  };

  export const handleAntiMeridian = <T extends GeoJSON.Geometry>(
    bufferedGeoJson: GeoJSON.Feature<T>
  ) => {
    if (bufferedGeoJson.geometry.type === 'Polygon') {
      const polgonFeature = bufferedGeoJson as GeoJSON.Feature<GeoJSON.Polygon>;
      const featureCoords = polgonFeature.geometry.coordinates[0];
      const newCoords = coords180to360(featureCoords);
      return {
        ...bufferedGeoJson,
        geometry: {
          ...bufferedGeoJson.geometry,
          coordinates: [newCoords],
        },
      } as GeoJSON.Feature<T>;
    }
    if (bufferedGeoJson.geometry.type === 'LineString') {
      const lineFeature =
        bufferedGeoJson as GeoJSON.Feature<GeoJSON.LineString>;
      const featureCoords = lineFeature.geometry.coordinates;
      const newCoords = coords180to360(featureCoords);
      return {
        ...bufferedGeoJson,
        geometry: {
          ...bufferedGeoJson.geometry,
          coordinates: newCoords,
        },
      } as GeoJSON.Feature<T>;
    }
    if (bufferedGeoJson.geometry.type === 'Point') {
      // one single point cannot cross the meridian
      return bufferedGeoJson as GeoJSON.Feature<T>;
    }
    throw Error('Unsupported GeoJson Type for handleAntiMeridian()');
  };

  /**
   * This helper function sets the GeoJSON Source data for a mapbox GeoJSON
   * layer. It handles the case where the data is a string, a single feature,
   * or a feature collection. It also handles the case where the data crosses
   * the antimeridian.
   *
   * @param map
   * @param sourceName
   * @param data
   * @returns
   */
  export const setMapboxGeoJSONSourceData = (
    sourceName: string,
    data: string | GeoJSON.FeatureCollection | GeoJSON.Feature
  ): GeoJSONSource => {
    let source = MapHelpers.getSource(sourceName);
    if (source && source.type !== 'geojson') {
      throw Error(`Source ${sourceName} is not a geojson source`);
    }

    let geoJsonData: GeoJSON.FeatureCollection;

    if (typeof data === 'string') {
      geoJsonData = JSON.parse(data);
    } else if ('geometry' in data) {
      geoJsonData = createGeoJSON([data]);
    } else {
      geoJsonData = data;
    }

    const fixedData = {
      ...geoJsonData,
      features: geoJsonData.features.map((feature) =>
        handleAntiMeridian(feature)
      ),
    };

    if (!source) {
      source = addSimpleGeoJsonSource(sourceName);
    }

    source.setData(fixedData);
    return source;
  };

  export const pointsNear = (
    a: { x: number; y: number } | null,
    b: { x: number; y: number },
    distance: number = 20
  ) => {
    if (!a) {
      return false;
    }
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    return dx * dx + dy * dy <= distance * distance;
  };

  export const getCountryBoundsFromShortcode = async (shortcode: string) => {
    const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    const result = await axios.get<MapboxGeocodeReturn>(
      `${baseUrl}/${shortcode}.json`,
      {
        params: {
          access_token: MAPBOX_ACCESS_TOKEN,
          types: 'country',
          limit: 1,
          autocomplete: false,
          country: shortcode,
        },
      }
    );
    if (
      result.data.features.length === 0 ||
      result.data.features[0].relevance !== 1 // reject if not exact match
    ) {
      return null;
    }
    return result.data.features[0].bbox;
  };
  export const getCityFromName = async (name: string) => {
    const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    const result = await axios.get<MapboxGeocodeReturn>(
      `${baseUrl}/${name}.json`,
      {
        params: {
          access_token: MAPBOX_ACCESS_TOKEN,
          types: 'place',
          limit: 1,
          autocomplete: false,
        },
      }
    );
    if (
      result.data.features.length === 0 ||
      result.data.features[0].relevance < 0.95 // can't reject on not exact match
    ) {
      return null;
    }
    return result.data.features[0];
  };

  export const calculateDistanceBetweenPoints = (
    point1: [number, number],
    point2: [number, number],
    unit: turf.Units = 'nauticalmiles'
  ) => turf.distance(point1, point2, { units: unit });

  export const createFeatureMultiLineString = (
    coordinates: GeoJSON.Position[][],
    properties: GeoJSON.GeoJsonProperties
  ): GeoJSON.Feature<GeoJSON.MultiLineString> => ({
    type: 'Feature',
    properties: {
      subType: DrawingShape.Line,
      ...properties,
    },
    geometry: {
      type: 'MultiLineString',
      coordinates,
    },
  });
}

export default GeoHelper;
